import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AgentConfigState {
  agentConfigVariant: string;
}

const initialState: AgentConfigState = {
  agentConfigVariant: '',
};

const agentConfigSlice = createSlice({
  name: 'agent-config',
  initialState,
  reducers: {
    setAgentConfigVariant: (
      state,
      agentConfigVariant: PayloadAction<string>,
    ) => {
      state.agentConfigVariant = agentConfigVariant.payload;
    },
  },
});

export const { setAgentConfigVariant } = agentConfigSlice.actions;

export default agentConfigSlice.reducer;
