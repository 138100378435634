import { ClientEnvironment } from '@polyai/common/constants/GlobalNew.constants';

import { doGet, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { generateNextPath } from 'lib/routeHelper';
import {
  AgentConfigFileResponse,
  AgentConfigFilesResponse,
  AgentConfigUpsert,
} from './types';

const BASE_TEMPLATE = 'accounts/:accountId/projects/:projectId/agent-config';
const FILE_TEMPLATE = `${BASE_TEMPLATE}/:fileName`;

export interface FileResponse {
  account_id: string;
  client_env: ClientEnvironment;
  created: boolean;
  ended_at: string;
  name: string;
  path: string;
  project_id: string;
  schema_version: string;
  started_at: string;
  updated: boolean;
}
export const getAgentConfigFiles = (accountId: string, projectId: string) => {
  return doGet<AgentConfigFilesResponse>(
    generateNextPath(BASE_TEMPLATE, { accountId, projectId }),
    LOGGER_TYPE.AGENT_CONFIG,
  );
};

export const getAgentConfigFile = (
  accountId: string,
  projectId: string,
  fileName: string,
  clientEnv: ClientEnvironment,
  activeVariant?: string,
) => {
  return doGet<AgentConfigFileResponse>(
    generateNextPath(
      FILE_TEMPLATE,
      {
        accountId,
        projectId,
        fileName,
      },
      { client_env: clientEnv, path: activeVariant },
    ),
    LOGGER_TYPE.AGENT_CONFIG,
  );
};

export const upsertAgentConfigFile = async ({
  fileName,
  accountId,
  projectId,
  ...payload
}: AgentConfigUpsert & {
  accountId: string;
  projectId: string;
}) => {
  return doPut<FileResponse>(
    generateNextPath(FILE_TEMPLATE, {
      accountId,
      projectId,
      fileName,
    }),
    LOGGER_TYPE.AGENT_CONFIG,
    payload,
  );
};
