import { doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';
import { CallReviewNote } from './types';

export const updateNote = (conversationId: string, note: string) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPut<CallReviewNote>(
    `accounts/${accountId}/projects/${projectId}/conversations/${conversationId}/review-notes`,
    LOGGER_TYPE.CONVERSATIONS,
    { note: note },
  );
};
