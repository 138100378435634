import hexToRgb from "styles/functions/hexToRgb";
import baseColors from "./baseColors";
import { ColorScheme, nodeColors, systemColors } from "./defaultTheme";

export const newThemeColors = {
  /** BACKGROUND **/
  background: baseColors.white,
  backgroundPage: baseColors.newGrey["05"],
  backgroundPrimary: baseColors.newGrey["100"],
  backgroundPrimaryHover: baseColors.newGrey["90"],
  backgroundPrimaryInverse: baseColors.newGrey["05"],
  backgroundPrimaryInverseHover: baseColors.white,
  backgroundBrand: baseColors.newBrand["50"],
  backgroundBrandInactive: baseColors.newGrey["50"],
  backgroundBrandInverse: baseColors.newBrand["50"],
  backgroundBrandHover: baseColors.newBrand["40"],
  backgroundAccent: baseColors.newGrey["05"],
  backgroundAccentInverse: baseColors.newGrey["90"],
  backgroundInverse: baseColors.newGrey["100"],
  backgroundInverseHover: baseColors.newGrey["90"],
  backgroundInverseDisabled: baseColors.newGrey["90"],
  backgroundInverseSkeleton: baseColors.newGrey["90"],

  backgroundHover: baseColors.offWhite,
  backgroundDisabledSubtle: baseColors.newGrey["20"],
  backgroundDisabledStrong: baseColors.newGrey["40"],
  backgroundSkeleton: baseColors.newGrey["20"],
  backgroundDanger: baseColors.red["50"],
  backgroundDangerHover: baseColors.red["90"],
  backgroundSuccess: baseColors.green["50"],
  backgroundSuccessHover: baseColors.green["90"],
  backgroundWarning: baseColors.yellow["50"],
  backgroundInformation: baseColors.blue["90"],
  backgroundError: baseColors.red["05"],
  backgroundOverlay: hexToRgb("#161617", 0.8),

  /** BORDER **/
  borderPrimary: baseColors.newGrey["100"],
  borderBrand: baseColors.newBrand["50"],
  borderBrandHover: baseColors.newGrey["100"],
  borderBrandActive: baseColors.newGrey["100"],
  borderBrandInverse: baseColors.newBrand["50"],
  borderBrandInverseHover: baseColors.newBrand["40"],
  borderBrandInverseActive: baseColors.newBrand["50"],
  borderSubtle: baseColors.newGrey["10"],
  borderSubtleInverse: baseColors.newGrey["90"],
  borderStrong: baseColors.newGrey["20"],
  borderStrongInverse: baseColors.newGrey["80"],
  borderUnderline: baseColors.newGrey["40"],
  borderUnderlineInverse: baseColors.newGrey["50"],

  borderFocus: baseColors.grey["80"],
  borderInverseFocus: baseColors.newGrey["20"],
  borderDisabled: baseColors.newGrey["20"],
  borderDanger: baseColors.red["50"],
  borderSuccess: baseColors.green["50"],
  borderWarning: baseColors.yellow["50"],
  borderInfo: baseColors.blue["50"],

  /** ICON **/
  iconPrimary: baseColors.newGrey["100"],
  iconPrimaryHover: baseColors.newGrey["20"],
  iconPrimaryBrand: baseColors.newGrey["100"],
  iconPrimaryInverse: baseColors.white,
  iconSecondary: baseColors.newGrey["70"],
  iconSecondaryInverse: baseColors.newGrey["40"],
  iconBrand: baseColors.newBrand["50"],
  iconBrandInverse: baseColors.newBrand["50"],
  iconAccent: baseColors.brand["50"],
  iconButtonBrand: baseColors.newGrey["100"],

  iconDisabled: baseColors.newGrey["50"],
  iconInverseDisabled: baseColors.newGrey["70"],
  iconDanger: baseColors.red["50"],
  iconSuccess: baseColors.green["50"],
  iconWarning: baseColors.yellow["50"],
  iconInfo: baseColors.blue["50"],

  /** TEXT **/
  textPrimary: baseColors.newGrey["100"],
  textPrimaryInverse: baseColors.white,
  textSecondary: baseColors.newGrey["70"],
  textSecondaryInverse: baseColors.newGrey["40"],
  textButtonBrand: baseColors.newGrey["100"],
  textHelper: baseColors.newGrey["50"],
  textHelperInverse: baseColors.newGrey["70"],
  textPlaceholder: baseColors.newGrey["50"],
  textPlaceholderInverse: baseColors.newGrey["60"],
  textBrand: baseColors.newBrand["60"],
  textBrandInverse: baseColors.newBrand["50"],
  textAccent: baseColors.brand["50"],

  textDisabled: baseColors.newGrey["50"],
  textInverseDisabled: baseColors.newGrey["70"],
  textDanger: baseColors.red["50"],
  textSuccess: baseColors.green["70"],
  textWarning: baseColors.yellow["90"],
  textInfo: baseColors.blue["50"],
};

const newColors = {
  ...systemColors,
  ...newThemeColors,
  ...nodeColors,
};

const newBrandTheme: ColorScheme = {
  colors: newColors,
  type: "light",
};

export default newBrandTheme;
