import { doDelete, doPut } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';
import { CallReviewAnnotation } from './types';

export const addCallReviewAnnotation = (
  conversationId: string,
  annotations: { turn_id: number; annotation_type: string; role: string }[],
) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doPut<CallReviewAnnotation>(
    `accounts/${accountId}/projects/${projectId}/conversations/${conversationId}/utterance-annotations`,
    LOGGER_TYPE.CONVERSATIONS,
    {
      annotations,
    },
  );
};

export const deleteCallReviewAnnotation = (
  conversationId: string,
  annotationIds: number[],
) => {
  const accountId = store.getState().account.id;
  const projectId = store.getState().project.id;

  return doDelete<CallReviewAnnotation>(
    `accounts/${accountId}/projects/${projectId}/conversations/${conversationId}/utterance-annotations`,
    LOGGER_TYPE.CONVERSATIONS,
    {
      annotation_ids: annotationIds,
    },
  );
};
