import {
  combineReducers,
  configureStore,
  PreloadedState,
} from '@reduxjs/toolkit';

import accountSlice from 'ducks/account/accountSlice';
import agentConfigSlice from 'ducks/agentConfig/agentConfigSlice';
import authSlice from 'ducks/auth/authSlice';
import callPanelSlice from 'ducks/callPanel/callPanelSlice';
import chatSlice from 'ducks/chat/chatSlice';
import chatPanelSlice from 'ducks/chatPanel/chatPanelSlice';
import functionsSlice from 'ducks/functions/functionsSlice';
import functionTestPanelSlice from 'ducks/functionTestPanel/functionTestPanelSlice';
import layoutSlice from 'ducks/layout/layoutSlice';
import pageStatusSlice from 'ducks/pageStatus/pageStatusSlice';
import projectSlice from 'ducks/project/projectSlice';
import tableSlice from 'ducks/table/tableSlice';
import testAgentSlice from 'ducks/testAgent/testAgentSlice';
import testAssistantSlice from 'ducks/testAssistantSlice/testAssistantSlice';
import uploadPanelSlice from 'ducks/uploadPanel/uploadPanelSlice';

const rootReducer = combineReducers({
  account: accountSlice,
  agentConfig: agentConfigSlice,
  auth: authSlice,
  callPanel: callPanelSlice,
  chatPanel: chatPanelSlice,
  functions: functionsSlice,
  layout: layoutSlice,
  pageStatus: pageStatusSlice,
  project: projectSlice,
  table: tableSlice,
  testAgent: testAgentSlice,
  testAssistant: testAssistantSlice,
  uploadPanel: uploadPanelSlice,
  chat: chatSlice,
  functionTestPanel: functionTestPanelSlice,
});

export const buildStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [
            'auth/setLogin',
            'auth/setLogout',
            'auth/setGetAuthToken',
          ],
          ignoredPaths: [
            'auth.login',
            'auth.logout',
            'auth.getAuthToken',
            'payload',
          ],
        },
      }),
  });
};

export const store = buildStore();

export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof buildStore>;
export type RootState = ReturnType<typeof rootReducer>;
