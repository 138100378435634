import React, { ReactNode, useRef } from "react";

import { CheckIcon } from "components/atoms/Icons";
import { IconSize } from "components/atoms/Icons/BaseIcon";

import * as Styled from "./PopupMenu.styled";

export interface PopupMenuItemProps
  extends React.HTMLAttributes<HTMLButtonElement> {
  isDisabled?: boolean;
  hasIcon?: boolean;
  iconSize?: keyof typeof IconSize;
  icon?: ReactNode;
  border?: boolean;
  isChecked?: boolean;
  isCreate?: boolean;
  isDelete?: boolean;
  isClickable?: boolean;
  blurOnClick?: boolean;
}

export { PopupMenuLabel } from "./PopupMenu.styled";

export const PopupMenuItem: React.FC<PopupMenuItemProps> = ({
  children,
  hasIcon,
  icon,
  iconSize = "xs",
  isDisabled = false,
  isChecked = false,
  isDelete = false,
  isCreate = false,
  isClickable = true,
  blurOnClick = false,
  onClick,
  ...props
}) => {
  const ref = useRef<HTMLButtonElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    if (blurOnClick) {
      ref.current?.blur();
    }
  };

  return (
    <Styled.PopupMenuLabel
      ref={ref}
      $clickable={isClickable}
      $create={isCreate}
      $delete={isDelete}
      disabled={isDisabled}
      forwardedAs="button"
      onClick={handleClick}
      {...props}
    >
      {(hasIcon || icon) && (
        <Styled.MenuItemIcon>
          {icon || (isChecked && <CheckIcon size={iconSize} />)}
        </Styled.MenuItemIcon>
      )}
      {children}
    </Styled.PopupMenuLabel>
  );
};
