import { doGet, doPost } from 'api/request';
import { LOGGER_TYPE } from 'api/types';
import { store } from 'reduxStore';
import {
  ConversationData,
  ConversationsResponse,
  CreateConversationResponse,
  CreateMessageResponse,
} from './types';

export const NUM_CONVERSATIONS_PER_PAGE = 50;
const DEFAULT_LANGUAGE = process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE;

export const getConversations = (
  query: string,
  limit: number = NUM_CONVERSATIONS_PER_PAGE,
) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;

  return doGet<ConversationsResponse>(
    'conversations',
    LOGGER_TYPE.CONVERSATIONS,
    {
      params: {
        account_id: accountId,
        project_id: projectId,
        limit,
      },
      query,
    },
  );
};

export const getConversationsXlsx = ({ query }: { query: string }) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;

  return doGet<Blob>(
    `accounts/${accountId}/projects/${projectId}/conversations/export`,
    LOGGER_TYPE.CONVERSATIONS,
    {
      query,
      downloadingFile: true,
    },
  );
};

export const getConversation = (
  conversationId: string,
  accountId?: string,
  projectId?: string,
  include_review?: boolean,
) => {
  return doGet<ConversationData>(
    `conversations/${conversationId}`,
    LOGGER_TYPE.CONVERSATIONS,
    {
      params: {
        account_id: accountId,
        project_id: projectId,
        include_review: include_review,
      },
    },
  );
};

export const createConversation = (
  currentPolicyVersion?: string,
  variantId?: string,
) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;
  return doPost<CreateConversationResponse>(
    'conversations',
    LOGGER_TYPE.CONVERSATIONS,
    {
      account_id: accountId,
      project_id: projectId,
      client_env: 'sandbox',
      variant_id: variantId,
      version: currentPolicyVersion,
    },
  );
};

export const getConversationAudio = ({
  conversationId,
  redacted = true,
  exportAudio = true,
}: {
  conversationId: string;
  redacted?: boolean;
  exportAudio?: boolean;
}) => {
  return doGet(
    `conversations/${conversationId}/audio`,
    LOGGER_TYPE.CONVERSATIONS,
    {
      params: {
        redacted,
      },
      downloadingFile: exportAudio,
    },
  );
};

export const sendMessage = (
  conversationId: string,
  text: string,
  currentPolicyVersion?: string,
  language: string | undefined = DEFAULT_LANGUAGE,
) => {
  const projectId = store.getState().project.id;
  const accountId = store.getState().account.id;

  return doPost<CreateMessageResponse>(
    `conversations/${conversationId}/turns`,
    LOGGER_TYPE.CONVERSATIONS,
    {
      account_id: accountId,
      project_id: projectId,
      client_env: 'sandbox',
      version: currentPolicyVersion,
      language: language,
      asr_lang_code: language,
      tts_lang_code: language,
      message: {
        text,
      },
      metadata: {},
    },
  );
};
